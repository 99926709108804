import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/cli-arm64",
  "date": "20th December 2021",
  "title": "Apple Silicon Support for Skpr CLI",
  "wip": true,
  "draft": true,
  "summary": "Skpr recently shipping CLI support for ARM64 devices!",
  "author": "Nick Schuch",
  "tag": "Development",
  "tagColor": "blue",
  "tags": [{
    "name": "arm"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`We are pleased to announce the release of ARM64 support for the Skpr CLI!`}</p>
    <p>{`Apple Silicon users rejoice because that means you! As of release `}<a parentName="p" {...{
        "href": "https://github.com/skpr/cli/releases/tag/v0.14.4"
      }}>{`v0.14.4`}</a>{` you are
now able to use the same `}<a parentName="p" {...{
        "href": "https://github.com/skpr/cli#homebrew"
      }}>{`Homebrew install method`}</a>{` as other OSX users to install the Skpr CLI.`}</p>
    <h2>{`Next Steps`}</h2>
    <p>{`We will now shift focus to local development environments and what improvements can be made, this maybe include compiling ARM64 images where appropriate.`}</p>
    <p>{`Stay tuned for further ARM64 related developments.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      